// import libraries and modules
import $ from 'jquery';

export default class Responsive {
	constructor() {
		this.initEvents();
	}

	initEvents() {
		this.handleResponsive();
		
	}

	handleResponsive() {
		this.resizeWindow();

		window.onresize = function onResizeWindow() {
			let width = window.innerWidth;
			if (width <= 550) {
				$('body > header p.logo').text('');
			} else {
				$('body > header p.logo').text('WeatherApp');
			}
		}
	}

	resizeWindow() {
		let width = window.innerWidth;
		if (width <= 550) {
			$('body > header p.logo').text('');
		} else {
			$('body > header p.logo').text('WeatherApp');
		}
	}
}
