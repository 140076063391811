// import libraries and modules
import axios from 'axios';
import $ from 'jquery';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export default class Mapbox {
    constructor () {
        this.initElements();
        this.initEvents();
    }

    initElements() {
        this.API_KEY = 'pk.eyJ1IjoianVsaWV0dGUtYm9pcyIsImEiOiJjbHcwb2Q1a2YwM3NjMmlwaHdyZWUzcmluIn0.Q5M4NbH8-4INUgW5gUIa8A';
        this.checkIfCoordsInSession();
    }

    initEvents() {
        this.onClickOpenButton();
        this.onClickCloseButton();
        this.onClickOverlay();
        this.onMapLoaded();
        this.onClickGeolocationButton();
        this.onSubmitSearchForm();
    }

    checkIfCoordsInSession() {
        let coords = this.getCoordsFromSession();
        this.initMap(coords);

    }

    // events
    onMapLoaded() {
        this.map.on('load', () => {
            let coords = this.getCoordsFromSession();

            // Add cursor on the map after la geolocation by IP of the user
            this.pulsingDot = this.createMarked();
            this.map.addImage('pulsing-dot', this.pulsingDot, { pixelRatio: 2 });
            this.map.addLayer({
                "id": "points",
                "type": "symbol",
                "source": {
                    "type": "geojson",
                    "data": {
                        "type": "FeatureCollection",
                        "features": [{
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [coords.longitude, coords.latitude]
                            }
                        }]
                    }
                },
                "layout": {
                    "icon-image": "pulsing-dot"
                }
            });
        });
    }

    onClickOpenButton() {
        $('header div.search').on('click', () => {
            this.displayMap();
        });
    }

    onClickCloseButton() {
        $('button#close').on('click', () => {
            this.closeMap();
        });
    }

    onClickOverlay() {
        $('div#overlay-map').on('click', () => {
            this.closeMap();
        });
    }

    onClickGeolocationButton() {
        this.geolocate.on('geolocate', e => {
            let position = [e.coords.longitude, e.coords.latitude];
            this.getCoordsWithMapbox(position)
            .then(response => {
                let coords = {};
                coords.city = response.data.features[0].context[1].text;
                coords.latitude = response.data.features[0].center[1];
                coords.longitude = response.data.features[0].center[0];
                this.setCoordsFromSession(coords);
            })
            .catch(error => {
                return error;
            });
        });
    }

    onSubmitSearchForm() {
        this.geocoder.on('result', e => {
            let coords = {};
            coords.latitude = e.result.center[1];
            coords.longitude = e.result.center[0];
            coords.city = e.result.text;
            this.setCoordsFromSession(coords);
        });
    }
    // --

    // getter & setter
    getCoordsFromSession() {
        return JSON.parse(sessionStorage.getItem('coords'));
    }

    setCoordsFromSession(coords) {
        sessionStorage.setItem('coords', JSON.stringify(coords));
    }
    // --

    // méthodes
    displayMap() {
        $('div#overlay-map').addClass('active');
        $('section#map-container').addClass('active');
        $('button#close').addClass('active');
        $('.mapboxgl-ctrl-geocoder--input').addClass('active');
        $('.mapboxgl-ctrl').addClass('active');
        $('body').addClass('active');
    }

    closeMap() {
        $('a.map').removeClass('active');
        $('section#map-container').removeClass('active');
        $('button#close').removeClass('active');
        $('div#overlay-map').removeClass('active');
        $('.mapboxgl-ctrl-geocoder--input').removeClass('active');
        $('.mapboxgl-ctrl').removeClass('active');
        $('body').removeClass('active');
    }

    getCoordsWithMapbox(position) {
        return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${position}.json?access_token=${this.API_KEY}`)
    }

    initMap(coords) {
        mapboxgl.accessToken = this.API_KEY;
        this.map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/light-v10',
            center: [coords.longitude, coords.latitude],
            zoom: 10
        });

        // display controls options
        this.navigation = new mapboxgl.NavigationControl();
        this.map.addControl(this.navigation);

        // display geolocation button
        this.geolocate = new mapboxgl.GeolocateControl();
        this.map.addControl(this.geolocate);

        // display search bar
        this.geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
        });
        this.map.addControl(this.geocoder);
    }

    createMarked() {
        let size = 150;
        let classContext = this;
        
        return {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // get rendering context for the map canvas when layer is added to the map
            onAdd() {
                let canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            // called once before every frame where the icon will be used
            render() {
                let duration = 1500;
                let t = (performance.now() % duration) / duration;

                let radius = size / 2 * 0.3;
                let outerRadius = size / 2 * 0.7 * t + radius;
                let context = this.context;

                // draw outer circle
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
                context.fillStyle = 'rgba(229, 49, 112,' + (1 - t) + ')';
                context.fill();

                // draw inner circle
                context.beginPath();
                context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                context.fillStyle = 'rgba(229, 49, 112, 1)';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // update this image's data with data from the canvas
                this.data = context.getImageData(0, 0, this.width, this.height).data;

                // continuously repaint the map, resulting in the smooth animation of the dot
                classContext.map.triggerRepaint();

                // return `true` to let the map know that the image was updated
                return true;
            }
        }
    }
    // --
}
