// import libraries and modules
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import ScrollReveal from 'scrollreveal';
import Flickity from 'flickity';

export default class Weather {
	constructor() {
		this.initElements();
		this.initEvents();
	}

	initElements() {
		this.FORECAST_DAYS = [1, 2];
		this.slider = new Flickity('.slider');
	}

	initEvents() {
		this.onInitApp();
		this.onClickDiscoverButton();
		$('body').css('opacity', '1');
	}

	// events
	onInitApp() {
		if ($('section#home').length > 0) {
			let coords = this.getCoordsFromSession();

			this.getDataFromWeatherApi(coords.city)
				.then((response) => {
					this.displayBackground(response);
					this.getForecastsWeather(response, this.FORECAST_DAYS);
					this.getWeather(response, 0);
					$('body').css('opacity', '1');
				})
				.catch(error => console.log(error));
		}
	}

	onClickDiscoverButton() {
		$('button#discover').on('click', () => {
			let coords = this.getCoordsFromSession();

			this.getDataFromWeatherApi(coords.city)
				.then((response) => {
					this.getForecastsWeather(response, this.FORECAST_DAYS);
					this.getWeather(response, 0);
				})
				.catch(error => console.log(error));

			this.closeMap();
		});
	}
	// --

	// getters & setters
	getCoordsFromSession() {
		return JSON.parse(sessionStorage.getItem('coords'));
	}

	setCoordsInSession(city) {
		let coords = {
			'city': city,
		};
		sessionStorage.setItem('coords', JSON.stringify(coords));
	}
	// --

	getDataFromWeatherApi(city) {
		return axios.get(`/api/weather.php?q=${city}&days=8`);
	}

	// methods
	closeMap() {
		$('a.map').removeClass('active');
		$('section#map-container').removeClass('active');
		$('button#close').removeClass('active');
		$('div#overlay-map').removeClass('active');
		$('.mapboxgl-ctrl-geocoder--input').removeClass('active');
		$('.mapboxgl-ctrl').removeClass('active');
		$('body').removeClass('active');
	}

	getData(response, day) {
		moment.locale('fr');
		let data = response.data.forecast.forecastday;

		return {
			'city': response.data.location.name,
			'current_temperature': response.data.current.temp_c,
			'date': moment(data[day].date).format('LL'),
			'description': data[day].day.condition.text,
			'min_temperature': data[day].day.mintemp_c,
			'max_temperature': data[day].day.maxtemp_c,
			'rain':data[day].day.daily_chance_of_rain,
			'wind': data[day].day.maxwind_kph,
			'sunrise': moment(data[day].astro.sunrise, 'hh:mm A').format('H[h]mm'),
			'sunset': moment(data[day].astro.sunset, 'hh:mm A').format('H[h]mm'),
			'raw_sunrise': data[day].astro.sunrise,
			'raw_sunset': data[day].astro.sunset,
			'icon': data[day].day.condition.icon,
		}
	}

	// forecast weather
	getForecastsWeather(apiResponse, days) {
		document.querySelector('section#home div.days-button-group').innerHTML = '';
		document.querySelector('section#home div.slider').innerHTML = '';
		
		days.forEach(day => {
			const data = this.getData(apiResponse, day);

			$('section#home div.days-button-group').append(`
				<button id="${day}">${data.date}</button>
			`);

			// home page
			$('section#home div.slider').append(`
				<div class="forecast" id="day${day}">
					<div>
						<img src="https:${data.icon}" alt="#">
						<p>${data.description}</p>
					</div>
					<div class="max_min">
						<div class="max">
							<img src="images/top_arrow.svg" alt="Température maximum">
							<p><span class="max_temp">${data.min_temperature}</span>°</p>
						</div>
						<div class="min">
							<img src="images/down_arrow.svg" alt="Température minimum">
							<p><span class="min_temp">${data.max_temperature}</span>°</p>
						</div>
					</div>
				</div>
			`);
		})
		
		if(this.slider) {
			this.slider.destroy();
		}

		this.slider = new Flickity('.slider', {
			cellAlign: 'center',
			cellSelector: '.forecast',
			pageDots: false,
			prevNextButtons: false,
			wrapAround: false
		});

		const navButtons = document.querySelectorAll('.days-button-group button');

		navButtons.forEach(button => {
			button.addEventListener('click', () => {
				this.slider.select(button.getAttribute('id') - 1);
			});
		});

		navButtons[0].classList.add('active');

		this.slider.on('change', function(index) {
			const activeCell = this.selectedElement.getAttribute('id').replace('day', '');
	
			const navButtons = document.querySelectorAll('.days-button-group button');
	
			navButtons.forEach(button => {
				button.classList.remove('active');
	
				if (button.getAttribute('id') === activeCell) {
					button.classList.add('active');
				}
			});
		});

		$('div.forecasts').css('opacity', '1');
	}

	// current weather
	getWeather(apiResponse, day) {
		const data = this.getData(apiResponse, day);

		$('section#home p.description').text(data.description);
		$('section#home p.city').text(data.city);
		$('section#home p.date').text(data.date);
		$('section#home span.temp').text(data.current_temperature);
		$('section#home span.min_temp').text(data.min_temperature);
		$('section#home span.max_temp').text(data.max_temperature);
		$('section#home span.rain').text(data.rain + ' %');
		$('section#home span.wind').text(data.wind + ' km/h');
		$('section#home span.sunrise').text(`${data.sunrise} (Heures d'Europe centrale)`);
		$('section#home span.sunset').text(`${data.sunset} (Heures d'Europe centrale)`);

		$('section#home header').css('opacity', '1');
		$('section#home div.wrap').css('opacity', '1');

		this.revealElements();
	}

	getSeason(current_date) {
		const month = current_date.getMonth() + 1; // On ajoute 1 pour obtenir des mois de 1 à 12
    	const day = current_date.getDate(); // Jour du mois

		const seasons = {
			"winter": { start: { month: 12, day: 20 }, end: { month: 3, day: 20 } }, // du 20 décembre au 20 mars
			"spring": { start: { month: 3, day: 21 }, end: { month: 6, day: 20 } }, // du 21 mars au 20 juin
			"summer": { start: { month: 6, day: 21 }, end: { month: 9, day: 22 } }, // du 21 juin au 22 septembre
			"autumn": { start: { month: 9, day: 23 }, end: { month: 12, day: 19 } } // du 23 septembre au 19 décembre
		};

		// Déterminer la saison en fonction du mois et du jour
		for (const season in seasons) {
			const { start, end } = seasons[season];
			const startMonth = start.month;
			const startDay = start.day;
			const endMonth = end.month;
			const endDay = end.day;

			if (
				(month === startMonth && day >= startDay) || // à partir du mois de début et du jour de début
				(month === endMonth && day <= endDay) ||     // jusqu'au mois de fin et au jour de fin
				(month > startMonth && month < endMonth)    // entre les mois de début et de fin
			) {
				return season;
			}
		}

		return null;
	}

	displayBackground(apiResponse) {
		const data = this.getData(apiResponse, 0);

		const backgrounds = ['winter', 'spring', 'summer', 'autumn', 'night'];
			
		const current_date = new Date(Date.now());
		const sunset = moment(data.raw_sunset, 'hh:mm A').toDate();
		const sunrise = moment(data.raw_sunrise, 'hh:mm A').toDate();
		const noon = new Date(Date.now());
		noon.setHours(12, 0, 0);
		
		if (current_date > noon) {
			sunrise.setDate(sunrise.getDate() + 1);
		} else {
			sunset.setDate(sunset.getDate() - 1);
		}
		
		const season = this.getSeason(current_date);

		if (sunset < current_date && current_date < sunrise) {
			$('section#home header > img').attr('src', `images/backgrounds/${backgrounds[4]}.png`)
			$('section#home header').css('color', '#fff')
			$('section#home header path:last-of-type').attr('fill', '#fff')
		} else {
			$('section#home header').css('color', '#1A171B')
			$('section#home header path:last-of-type').attr('fill', '#1A171B')

			if (season === 'winter') {
				$('section#home header > img').attr('src', `images/backgrounds/${backgrounds[0]}.png`)
				$('section#home header > img').attr('alt', 'Hiver')
			} else if (season === 'spring') {
				$('section#home header > img').attr('src', `images/backgrounds/${backgrounds[1]}.png`)
				$('section#home header > img').attr('alt', 'Printemps')
			} else if (season === 'summer') {
				$('section#home header > img').attr('src', `images/backgrounds/${backgrounds[2]}.png`)
				$('section#home header > img').attr('alt', 'Eté')
			} else if (season === 'automn') {
				$('section#home header > img').attr('src', `images/backgrounds/${backgrounds[3]}.png`)
				$('section#home header > img').attr('alt', 'Automne')
			}
		}
	}

	revealElements() {
		const options_bottom = {
			reset: false,
			distance: '50px',
			duration: 1000,
			scale: 1,
			easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
			viewFactor: 0,
			origin: 'bottom',
			delay: 350,
			interval: 50,
		};

		ScrollReveal().reveal('.scroll_reveal', options_bottom);
	}
	// --
}
