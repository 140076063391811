// import libraries and modules
import $ from 'jquery';
import Geolocation from './classes/geolocation.js';
import Weather from './classes/weather.js';
import Mapbox from './classes/mapbox.js';
import Responsive from './classes/responsive.js';

class App {
	constructor() {
		this.initApp();
	}

	initApp() {
		$(document).ready(() => {
			// instantiate the modules
			const geolocation = new Geolocation();
			geolocation.getCurrentIp().then(() => {
				new Weather();
				new Mapbox();
				new Responsive();
			}).catch((error) => {
				console.log(error);
			});
		});
	}
}

new App();
