// import libraries and modules
import axios from 'axios';

export default class Geolocation {
	constructor () { }

	async getCurrentIp() {
		let response = await axios.get('/api/ip.php');
		this.setCurrentCoords(response.data);
	}

	setCurrentCoords(data) {
		let coords = {
			'latitude': data.lat,
			'longitude': data.lon,
			'city': data.city,
		};
		coords = JSON.stringify(coords);
		sessionStorage.setItem('coords', coords);
	}

	getCoordsFromSession() {
		return JSON.parse(sessionStorage.getItem('coords'));
	}
}
